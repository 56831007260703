import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import TinyPhoto from "../components/TinyPhoto/TinyPhoto";
import TinyClear from "../components/TinyPhoto/TinyClear";
import config from "../../data/SiteConfig";

class AboutPage extends Component {
  render() {
    return (
      <Layout>
        <div className="singlePage">
          <Helmet title={`♡ ${config.siteTitle}`} />
          <div>
            <TinyPhoto src="/img/IMG_8199.PNG" />
            <TinyPhoto src="/img/IMG_8199.PNG" caption="captioned" />
            <TinyPhoto src="/img/IMG_8199.PNG" alt="new alt text" caption="new alt text" />
            <TinyPhoto src="/img/IMG_8199.PNG" small caption="small" />
            <TinyPhoto src="/img/IMG_8199.PNG" medium caption="medium" />
            <TinyPhoto src="/img/IMG_8199.PNG" large caption="large" />

            <TinyClear />
            <TinyPhoto src="/img/IMG_8199.PNG" small left caption="left" />
            <TinyClear />
            <TinyPhoto src="/img/IMG_8199.PNG" small right caption="right small" />
            <TinyClear />
            <TinyPhoto src="/img/IMG_8199.PNG" medium right caption="right medium" />
            <TinyClear />
            <TinyPhoto src="/img/IMG_8199.PNG" large right caption="right large" />
            <TinyClear />
            <TinyPhoto src="/img/IMG_8199.PNG" small center caption="center" />
            
            <TinyPhoto src="/img/IMG_8199.PNG" medium left caption="left col" />
            <TinyPhoto src="/img/IMG_8199.PNG" medium right caption="right col" />
            <TinyClear />

            <TinyPhoto src="/img/IMG_8199.PNG" medium right col caption="right .col" />
            <TinyPhoto src="/img/IMG_8199.PNG" medium left col caption="left .col" />
            <TinyClear />

            <TinyPhoto src="/img/IMG_5628.JPG" col medium left />
            <TinyPhoto src="/img/IMG_5629.JPG" col medium right />
            <TinyClear />

            <TinyPhoto src="/img/IMG_8199.PNG" linked caption="linked" />

            <TinyPhoto src="/emoji/happy_halloween4.gif" point />

            Another line of text here!

            <TinyPhoto src="/emoji/line.gif" point />

            Another line of text here! The middle one below is not "point".

            <TinyPhoto src="/emoji/line.gif" point />
            <TinyPhoto src="/emoji/line.gif" />
            <TinyPhoto src="/emoji/line.gif" point />

            Size tests below for : 50 , 100 , 200, 5000

            <TinyPhoto src="/img/IMG_8199.PNG" width="50" />
            <TinyPhoto src="/img/IMG_8199.PNG" width="100" />
            <TinyPhoto src="/img/IMG_8199.PNG" width="200" />
            <TinyPhoto src="/img/IMG_8199.PNG" width="5000" />
          </div>
        </div>
      </Layout>
    );
  }
}

export default AboutPage;